let {tail} = window.platformData() || {};

new window.Vue({
    el: '#tail-container',
    mixins: [window.globalMixin],
    data () {
        return {
            resizeDetector: window.elementResizeDetectorMaker(),
            tailHeight: 0,
            tail,
            link: null
        };
    },
    methods: {
        handleResize (element) {
            this.tailHeight = element.clientHeight;
        },
        bindEvent () {
            this.resizeDetector.listenTo(this.$el, this.handleResize);
        },
        unbindEvent () {
            this.resizeDetector.removeListener(this.$el, this.handleResize);
        },
        goFeedBack () {
            this.toUrl('/learning/feedback');
        },
        goQuestion () {

        }
    },
    mounted () {
        this.bindEvent();
        this.link = tail[0]
    },
    beforeDestroy () {
        this.unbindEvent();
    },
    watch: {
        tailHeight (val) {
            document.querySelector('body').style.paddingBottom = `${val}px`;
        }
    }
});
